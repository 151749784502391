import { useEffect } from 'react'
import tw from 'twin.macro'
import parse from 'html-react-parser'

import FlexSection from '@components/FlexSection'
import { parseContent } from '@components/Blocks'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_SimpleText,
  WpPrograma_Acfpagefields_Content_SimpleText
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_SimpleText
  | WpPrograma_Acfpagefields_Content_SimpleText

const SimpleText = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { text, title, bgColor, tagTitle } = data

  const sectionColors = findColors(bgColor)

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      id={title || ''}
      paddingBottom
      paddingTop
      tabs={tabs}
    >
      {text && (
        <div
          className="allow-wide-images"
          tw="prose mx-auto mb-6 text-center!"
          css={[sectionColors.text === 'light' && tw`text-white!`]}
        >
          {text && parse(text, { replace: parseContent })}
        </div>
      )}
    </FlexSection>
  )
}

export default SimpleText
